<template>
  <div class="main-container asset_statistics">
    <div class="stc-top">
      <ul>
        <li>
          <div class="item-left">
            <img src="../../assets/img/all.png" alt="">
          </div>
          <div class="item-right">
            <p>所有仪器</p>
            <span class="has_scrap" style="color:#1296db;">{{total}}</span>
          </div>
        </li>
        <li>
          <div class="item-left">
            <img src="../../assets/img/using.png" alt="">
          </div>
          <div class="item-right">
            <p>使用中</p>
            <span class="using">{{asset_status_list[0].status_count}}</span>
          </div>
        </li>
        <li >
          <div class="item-left">
            <img src="../../assets/img/wait_out.png" alt="">
          </div>
          <div class="item-right">
            <p>停用中(医院)</p>
            <span class="wait_out">{{asset_status_list[1].status_count}}</span>
          </div>
        </li>
        <li>
          <div class="item-left">
            <img src="../../assets/img/fixing.png" alt="">
          </div>
          <div class="item-right">
            <p>停用中(经销商)</p>
            <span class="fixing">{{asset_status_list[2].status_count}}</span>
          </div>
        </li>
        <li>
          <div class="item-left">
            <img src="../../assets/img/wait_deliver.png" alt="">
          </div>
          <div class="item-right">
            <p>返回厂内</p>
            <span class="wait_deliver">{{asset_status_list[3].status_count}}</span>
          </div>
        </li>
<!--        <li>-->
<!--          <div class="item-left">-->
<!--            <img src="../../assets/img/wait_install.png" alt="">-->
<!--          </div>-->
<!--          <div class="item-right">-->
<!--            <p>待装机</p>-->
<!--            <span class="wait_install">{{asset_status_list[3].status_count}}</span>-->
<!--          </div>-->
<!--        </li>-->

      </ul>
    </div>
    <div class="area_statistics statistics">
      <p>区域统计</p>
      <area-statistics id="areaEcharts" v-if="isAreaEcharts" :areaEchartData="areaEchartData"></area-statistics>
    </div>
    <div class="stic">
      <div class="plat_statistics statistics">
        <p>平台统计 <span>单位：台</span></p>
        <plat-statistics id="platEcharts" v-if="isPlat" :platData="platData"></plat-statistics>
      </div>
      <div class="plat_statistics statistics">
        <p>按资产状态统计  <span>单位：台</span></p>
        <asset-status-statistics id="assetEcharts" v-if="isAsset" :assetData="assetData"></asset-status-statistics>
      </div>
      <div class="plat_statistics statistics">
        <p>按医院级别统计 <span>单位：台</span></p>
        <hospital-statistics id="hospitalEcharts" :hospitalData="hospitalData"></hospital-statistics>
      </div>
    </div>


  </div>
</template>
<script>
import AreaStatistics from "@/views/AssetStatistics/components/areaStatistics.vue";
import PlatStatistics from "@/views/AssetStatistics/components/platStatistics.vue";
import AssetStatusStatistics from "@/views/AssetStatistics/components/assetStatusStatistics.vue";
import HospitalStatistics from "@/views/AssetStatistics/components/hospitalStatistics.vue";
import {assetConstitute} from "@/api/assetMgt";

export default {
  name: "index",
  components: {HospitalStatistics, AssetStatusStatistics, PlatStatistics, AreaStatistics},
  data(){
    return{
      isAreaEcharts: false,
      isPlat: false,
      isAsset: false,
      isHospital: false,
      asset_status_list: [],
      total: 0,
      platData: [],
      assetData: [],
      hospitalData: [],
      areaEchartData: {
        x: [],
        y: []
      },
    }
  },
  created() {
    this.getData()
  },
  methods:{
    getData(){
      assetConstitute().then(res => {
        if(res){
          let province_count =  res.data.province_count
          let platform_count =  res.data.platform_count
          let hospital_level =  res.data.hospital_level
          this.asset_status_list = res.data.asset_status_count
          this.total =  this.asset_status_list.reduce((a,b) => a + b.status_count,0)
          // 区域统计
          if(province_count.length > 0){
            this.isAreaEcharts = true
            this.areaEchartData.x = province_count.map(item =>item.province_name)
            this.areaEchartData.y = province_count.map(item =>item.province_count)
          }
          // 平台统计
          if(platform_count.length > 0){
            this.isPlat = true
            platform_count.forEach(item =>{
              item.name = item.platform_name
              item.value = item.platform_count
            })
            this.platData = platform_count
          }
          // 资产统计
          if(this.asset_status_list.length > 0){
            this.isAsset = true
            this.asset_status_list.forEach(item =>{
              item.name = item.status_name
              item.value = item.status_count
            })
            this.assetData = this.asset_status_list
          }
          // 医院等级
          if(hospital_level.length > 0){
            this.isHospital = true
            this.hospitalData.x = hospital_level.map(item =>item.level_name)
            this.hospitalData.y = hospital_level.map(item =>item.level_count)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.main-container{
  background: #fff;
  margin: 14px;
  padding:14px ;
}
.asset_statistics{

}
.stc-top {
  ul {
    display: flex;
    justify-content: space-between;
    li{
      display: inline-block;
      width: 19%;
      height: 7rem;
      line-height: 7rem;
      border: 1px solid  #D0D0D0;
      //box-shadow: 3px 3px 5px #d0d0d054;
      border-radius: 3px;
      .item-left{
        display: inline-block;
        width: 30%;
        box-sizing: border-box;
        padding: 0.3rem 1.5rem 0  1.5rem;
        img{
          display: inline-block;
          width: px(40);
        }
      }
      .item-right{
        display: inline-block;
        width: 62%;
        vertical-align: top;
        font-size: 0;
        //padding-left: px(10);
        p{
          font-size: px(24);
          height: 2rem;
          line-height: 2rem;
          margin-top: px(24);
        }
        span{
          display: inline-block;
          font-size: px(40);
          line-height: px(30);
          vertical-align: top;
          margin-top:10px;
        }
        span.using{
          color: #81B337!important;
          background: #fff!important;
        }
        span.wait_out{
          color: #3b0e7b;
        }
        span.fixing{
        color: #ffbf6b;
        }
        span.wait_deliver{
          color: #de868f;
        }
        span.wait_install{
          color: #744e20;
        }
        span.has_scrap{
          color: #9a9a9a;
        }
      }
    }



  }
}
.statistics{
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  margin-top: px(24);
  p{
    color: #347CAF;
    border-bottom: 1px solid #D0D0D0;
    font-size: px(26);
    padding: px(16) px(20)  px(16) px(30);
    span{
      font-size: px(18);
      float: right;
      color: #000;
    }
  }
}
.stic{
  display: flex;
  justify-content: space-between;
  .statistics{
    width: 32%;
  }

}
</style>