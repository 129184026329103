import request from '@/utils/request'

export function getAssetList(params) { // 资产列表
    return request({
        url: '/api/asset_list/',
        method: 'get',
        params
    })
}
export function getAssetDtl(params) {  // 资产详情
    return request({
        url: '/api/asset_detail/',
        method: 'get',
        params
    })
}
export function getMaintenanceRecords(params) {  // 资产详情-维保列表
    return request({
        url: '/api/maintenance_records/',
        method: 'get',
        params
    })
}

export function getAssetCirculation(params) {  // 资产详情-流转列表
    return request({
        url: '/api/asset_circulation/',
        method: 'get',
        params
    })
}
export function saveCheckInfo(data) {  // 出库单信息提交
    return request({
        url: '/api/fill_checkout_info/',
        method: 'post',
        data
    })
}
export function getCheckInfoTemp(params) {  // 获取暂存出库单信息
    return request({
        url: '/api/fill_checkout_info/',
        method: 'get',
        params
    })
}
export function saveExpress(data) {  // 物流信息提交
    return request({
        url: '/api/fill_express/',
        method: 'post',
        data
    })
}

export function checkExpress(params) {  // 查看物流信息
    return request({
        url: '/api/fill_express/',
        method: 'get',
        params
    })
}
export function moveRecord(data) {  // 移机
    return request({
        url: '/api/add_circulation/',
        method: 'post',
        data
    })
}
export function getMoveRecord(params) {  // 移机
    return request({
        url: '/api/add_circulation/',
        method: 'get',
        params
    })
}
export function changeStatus(data) {  // 修改资产状态
    return request({
        url: '/api/change_asset_status/',
        method: 'post',
        data
    })
}
export function returnApply(data) {  // 新增返厂申请
    return request({
        url: '/api/return_apply/',
        method: 'post',
        data
    })
}
export function getReturnApply(params) {  //获取暂存的返厂申请
    return request({
        url: '/api/return_apply/',
        method: 'get',
        params
    })
}
export function applyDetail(params) {  // 查看返厂信息
    return request({
        url: '/api/return_apply_detail/',
        method: 'get',
        params
    })
}
export function approvalApplication(data) {  // 返厂申请审批
    return request({
        url: '/api/approval_application/',
        method: 'post',
        data
    })
}
export function exportAsset(params) {  // 查看返厂信息
    return request({
        url: '/api/export_asset/',
        method: 'get',
        params
    })
}
// 资产统计
export function assetConstitute(params) {  // 查看返厂信息
    return request({
        url: '/api/asset_constitute/',
        method: 'get',
        params
    })
}


export function uploadFile(data) {  // 上传文件
    return request({
        url: '/api/upload_asset_file/',
        method: 'post',
        data
    })
}



