<template>
  <div class="area_echart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from '@/assets/js/echartsMixins.js'
export default {
  props: ['id', 'hospitalData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      orderNumList: [],
      orderSumList: [],
      provincelist: [],
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.handleData()
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize() {
      this.myChart.resize()
    },
    // 数据初步处理
    handleData() {

    },
    initEcharts() {
      var that = this
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.hospitalData.x
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            label: {show: true}, // 显示折点数值
            lineStyle: {width: 2, },// 线宽
            itemStyle: {
              normal: {
                color: '#F39423', // 这里设置点的颜色为红色
                lineStyle: {
                  color: '#F39423', // 这里设置线的颜色为红色
                }
              }
            },
            // name: 'Email',
            type: 'line',
            stack: 'Total',
            areaStyle: {
              normal: {
                color: '#FDEAD3' // 区域背景色
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: this.hospitalData.y
          },


        ]
      };
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.area_echart {
  width: 100%;
  position: relative;

  .echarts {
    width: 100%;
    height: px(420);
  }
}
</style>
