<template>
  <div class="area_echart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from '@/assets/js/echartsMixins.js'
export default {
  props: ['id', 'areaEchartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      orderNumList: [],
      orderSumList: [],
      provincelist: [],

    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.handleData()
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize() {
      this.myChart.resize()
    },
    // 数据初步处理
    handleData() {

    },
    initEcharts() {
      var that = this
      var option = {
        legend: {
          show: true,
          // right: '10%',
          bottom: 2,
          // icon: 'rect',
          itemWidth: 40,
          itemHeight: 18,
          // textStyle: {
          //   color: '#81b337',
          //   fontSize: 12,
          // },
          data:["使用中"]
        },
        label: {
          show: true, // 显示数值
          position: 'outside', // 数值显示在扇区外侧
          formatter: '{c} ', // 自定义标签的回调函数
        },
        color: ['#81B337'],
        xAxis: {
          type: 'category',
          axisLabel: {
            interval:0
          },
          data: this.areaEchartData.x
        },
        yAxis: {
          type: 'value'
        },
        grid:{
          left: "60px",
          right: "30px",
          top: "50px",
          bottom: "80px",
        },
        series: [
          {
            name: "使用中",
            data:  this.areaEchartData.y,
            type: 'bar',
            mapLocation:{
              x: '10',
              y: '10',
              width: '10%',
              height: '50%',
            },
          },

        ]
      };
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.area_echart {
  width: 100%;
  position: relative;

  .echarts {
    width: 100%;
    height: px(420);
  }
}
</style>
