<template>
  <div class="area_echart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from '@/assets/js/echartsMixins.js'
export default {
  props: ['id', 'assetData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      orderNumList: [],
      orderSumList: [],
      provincelist: [],
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.handleData()
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize() {
      this.myChart.resize()
    },
    // 数据初步处理
    handleData() {},
    initEcharts() {
      var that = this
      var option = {
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
          formatter: '{b} : {c} ({d}%)'
        },
        legend: {
          show: false // 这里设置图例不显示
        },
        series: [
          {
            label: {
              fontSize:'10',
              show: true, // 显示数值
              position: 'outside', // 数值显示在扇区外侧
              // formatter: '{b}: {c} ' // 自定义标签的回调函数
              formatter: function (params) {
                // 假设我们按照每10个字符进行换行（仅为示例，实际应根据需要调整）
                var text = params.name;
                var lines = [];
                for (var i = 0; i < text.length; i += 10) {
                  lines.push(text.substring(i, i + 10));
                }
                return lines.join('\n') +  '\n'+ `${params.value}`;
              },
            },
            type: 'pie',
            radius: '50%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            avoidLabelOverlap: false,  // 是否启用防止标签重叠策略
            data: this.assetData,
            minAngle: 2, //最小角度
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.area_echart {
  width: 100%;
  position: relative;

  .echarts {
    width: 100%;
    height: px(420);
  }
}
</style>
